<template>
    <b-modal id="cityModalEdit" title="Изменить данные города" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Город</label>
                        <b-input v-model="city.name_ru" placeholder="Худжанд" />
                    </b-col>
                    <b-col cols="6">
                        <label>Город на анлийсском</label>
                        <b-input v-model="city.name" placeholder="Khujand" />
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <label>Выберите регион</label>
                        <v-select
                            v-model="city.region_id"
                            placeholder="Регион"
                            label="name_ru"
                            :options="regions"
                            :reduce="options => options.id"
                            class="select-size-md"
                        />
                    </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editCity()">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                        Отмена
                    </b-button>
                </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    props: ['id','regions'],
    components: {
        vSelect,
        ToastificationContent,
    },
    data(){
        return {
            city: {
                name: null, 
                name_ru: null,
                coordinates: null,
                region_id: null
            },
            submitButtonDisabled: false,
        }
    },
    mounted(){
    },
    methods: {
        opened(){
            this.$http
                .get(`addresses/cities/${this.id.id}/edit`)
                .then(res => {
                    this.city.name = res.data.name,
                    this.city.name_ru = res.data.name_ru
                    this.city.region_id = res.data.region_id
                    this.city.coordinates = res.data.coordinates
            })
        },
        editCity(){
            this.city.coordinates == null ? this.city.coordinates = undefined  : this.city.coordinates 
            this.submitButtonDisabled = true
            this.$http
                .patch(`addresses/cities/${this.id.id}`, this.city)
                .then(res => {
                    this.$bvModal.hide('cityModalEdit')
                    this.btnCancel()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Город успешно изменен!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        btnCancel(){
            this.$bvModal.hide('cityModalEdit')
            this.city = {
                name: null, 
                name_ru: null,
                coordinates: null,
                region_id: null
            }
        }
    }

}
</script>