<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить город" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Выберите город</label>
                        <b-input v-model="city.name_ru" placeholder="Худжанд" />
                    </b-col>
                     <b-col cols="6">
                        <label>Город</label>
                        <b-input v-model="city.name" placeholder="Khujand" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-2" cols="12">
                        <label>Выберите регион</label>
                        <v-select
                            v-model="region_select"
                            @input="setSelected"
                            placeholder="Регион"
                            label="name"
                            :options="regions"
                            class="select-size-md"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
                
        <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createCity()">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel()">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>        
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        vSelect,
        ToastificationContent,
    },
    data(){
        return {
            city: {
                name: '',
                region_id: '',
                name_ru: '',
            },
            region_select: undefined,
            regions: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        createCity(){
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/cities', this.city)
                .then(res => {
                    // this.$bvModal.hide('cityModal')
                    this.btnCancel()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Город успешно добавлен!`,
                        },
                    })
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get('addresses/regions').then(res => {
                    this.regions = res.data
                })
            this.clearData()
        },
        setSelected(){
            this.city.region_id = this.region_select.id
        },
        btnCancel(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.city = {
                name: '',
                region_id: '',
                name_ru: '',
            },
            this.region_select = undefined,
            this.regions = []
        }
    }
}
</script>