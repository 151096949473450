<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>       
        <div v-else>
        <table-city :cities="cities" @editForm="editForm" @refresh="refresh" />
        <modal-city @refresh="refresh" />
        <modal-city-edit :id="id" @refresh="refresh" :regions="regions"/>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableCity from "@/views/component/Table/tableCity.vue"
import modalCity from "@/views/component/Modal/ModalCity/modalCity.vue"
import modalCityEdit from "@/views/component/Modal/ModalCity/modalCityEdit.vue"
export default {
    components: {
        tableCity,
        modalCity,
        modalCityEdit,
    },
    data(){
        return {
            id: '',
            cities: [],
            showPreloader: false,
            regions: [],
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'name', label: 'Город(англ.)', sortable: true, thStyle: {width: '150px'}},
                { key: 'name_ru', label: 'Город', sortable: true, thStyle: {width: '150px'}},
                { key: 'region', label: 'Регион', sortable: true, thStyle: {width: '150px'}}
      ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
        this.region()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('cityModalEdit')
        },
        refresh(){
            this.showPreloader = true   
            this.$http
                .get('addresses/cities').then(res => {
                    this.showPreloader = false    
                    this.cities = res.data
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        region() {
            this.$http
                .get(`addresses/regions`)
                .then(res => {
                    this.regions = res.data
            })
        },
        sendToParent(tableData){
            this.cities = tableData
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.cities = res.data
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.cities = res.data
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>